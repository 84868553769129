<template>
  <a-modal :visible="windowShow" title="大车操作" width="70%" footer="" @cancel="cancelClose"
    :bodyStyle="{ padding: '0 0 15px 15px' }">
    <div style="display:flex">
      <div class="left-content" style="width:70%">
        <!-- 运输车信息 -->
        <div>
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/basic.png" />
            &emsp;
            <span style="font-weight:bold;color:#344563">运输车信息</span>
          </div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="2">
                <span class="title-color">线路名称</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ truckData.lineName }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">安排时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.loadingTime }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.truckStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2">
                <span class="title-color">大车运费</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ truckData.freightCost }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">已占&emsp;&emsp;</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.usedParkPlace }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">承运商</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ truckData.carrierName }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2">
                <span class="title-color">是否到达</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ truckData.truckStatus?.value === 4 ? '是' : '否' }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">司机姓名</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.driverName }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">司机电话</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.driverTel }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2">
                <span class="title-color">超期&emsp;&emsp;</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ truckData.gpsLine?.isOverDue.value === 0 ? '正常运行' : '已超期' }}</span>
                <span class="data-color" v-show="truckData.gpsLine?.remainingKm">{{ ' ' + '剩余' +
                  truckData.gpsLine?.remainingKm }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">GPS类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.gpsType?.label }}</span>
                <a v-show="truckData.truckStatus?.value !== 4 && truckData.truckStatus?.value !== 5"
                  @click="changeGPSshow = true">修改</a>
              </a-col>
              <a-col :span="2">
                <span class="title-color">GPS状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ truckData.gpsLine?.gpsStatus?.label }}</span>
              </a-col>
            </a-row>
          </div>
          <div class="content-style" style="display:flex;justify-content:flex-end;margin:10px 0;">
            <a-space>
              <!-- <a v-show="planVehicles.length > 0 && truckData.truckStatus?.value === 1" @click="getPassword">出库密码</a> -->
              <!-- <a-button v-show="truckData.truckStatus?.value !== 5" type="primary"
                @click="sendCarrierShow = true">承运商运单发送</a-button> -->
              <a-button v-show="planVehicles.length > 0 && truckData.truckStatus?.value === 1" type="primary"
                @click="receivables = true">发车
              </a-button>
              <!--            <a-button v-show="truckData.truckStatus.value===2" type="primary" style="background:#0077B8"-->
              <!--                      @click="transitShow=true">中途到达-->
              <!--            </a-button>-->
              <!-- <a-popconfirm v-show="truckData.truckStatus?.value === 2" title="确认到达" ok-text="确认" cancel-text="关闭" @confirm="confirmArrival">
                <a-button type="primary"
                  style="background:#07C160">到达</a-button>
              </a-popconfirm> -->
              <a-popconfirm title="确认取消该任务" ok-text="确认" cancel-text="关闭" @confirm="cancelPlan">
                <a-button v-show="truckData.truckStatus?.value === 0" type="primary"
                  style="background:#FF5722;border:none">取消任务
                </a-button>
              </a-popconfirm>
              <a-button v-show="truckData.truckStatus?.value <= 1" @click="deiverShow = true">更换地跑运输</a-button>
              <a-button v-show="truckData.truckStatus?.value <= 1" @click="changeShow = true">更换大车</a-button>
              <a-button v-show="truckData.truckStatus?.value <= 1" @click="changeLineData = true"
                style="background:greenyellow;">
                更改线路及承运商
              </a-button>
              <!-- <a-button type="primary" style="background:#FFB800">故障</a-button> -->
            </a-space>
          </div>
        </div>
        <!-- 历史装运车 -->
        <div v-show="truckData.truckStatus?.value === 4">
          <div class="content-title">历史装运小车</div>
          <div>
            <a-table ref="tableRef" size="small" :columns="historyColumns" :row-key="record => record.orderVehicleId"
              :data-source="historyList" bordered :pagination="false" :loading="loading">
              <template #brand="{ record }">
                <div>
                  <span>{{ record.brand + record.model }}</span>
                </div>
              </template>
              <template #address="{ record }">
                <div>
                  <div>起：{{ record.startAddress }}</div>
                  <div>终：{{ record.endAddress }}</div>
                </div>
              </template>
            </a-table>
          </div>
        </div>
        <!-- 已安排车辆 -->
        <div v-if="planVehicles.length > 0">
          <div class="content-title" style="display: flex;justify-content: space-between;">
            <div>
              <img class="img" src="@/assets/images/carrier/car-success.png">
              <span style="font-weight:bold;color:#344563">已安排车辆</span>
            </div>
            <a v-if="!element.truckStatus && truckData.truckStatus?.value === 2" @click="batchTruckShow = true"
              style="color:#f00;">批量到达</a>
          </div>
          <a-table :data-source="planVehicles" v-model:columns="arrangedColumns" :pagination="ArrangedPagination"
            :loading="arrangedLoading" :rowKey="(record, index) => { return index }" size="small" bordered
            @change="arrangedChange">
            <template #vinNo="{ record }">
              <div><a @click="lookDetail(record)">{{ record.vinNo }}</a></div>
            </template>
            <template #brand="{ record }">
              <div>{{ record.brand + record.model }}</div>
            </template>
            <template #address="{ record }">
              <div>
                <div>起：{{ record.startAddress }}</div>
                <div>终：{{ record.endAddress }}</div>
              </div>
            </template>
            <template #stockType="{ record }">
              <div>
                <div v-if="record.stockType">状态：{{ record.stockType.label }}</div>
                <div>城市：{{ record.pname }}</div>
              </div>
            </template>
            <template #store="{ record }">
              <div v-if="record.isShop">
                <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
                  v-model:checked="record.isShop.value" @change="isShopChange(record)" />
              </div>
            </template>
            <template #operation="{ record }">
              <div>
                <a-popconfirm title="确认取消装车？" ok-text="确认" cancel-text="关闭" @confirm="cancel(record)">
                  <a class="mrg-10" v-if="!element.truckStatus" v-show="truckData.truckStatus.value === 1">卸车</a>
                </a-popconfirm>
                <a-popconfirm title="确认退板？" ok-text="确认" cancel-text="关闭" @confirm="backCar(record)">
                  <a v-if="!element.truckStatus" v-show="truckData.truckStatus.value === 2" style="color:#f00">退板</a>
                </a-popconfirm>
                <a-divider type="vertical" v-if="!element.truckStatus" v-show="truckData.truckStatus.value === 2" />
                <a v-if="!element.truckStatus" v-show="truckData.truckStatus.value === 2"
                  @click="transitShow = true, transOrderVehicleId = [record.transOrderTruckVehicleId]"
                  style="color:#f00">到达</a>
              </div>
            </template>
          </a-table>
        </div>
        <!-- 可安排小车 -->
        <div v-show="license && truckData.truckStatus?.value === 1 || truckData.truckStatus?.value === 0">
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/u299.png">
            &emsp;
            <span style="font-weight:bold;color:#344563">可安排小车</span>
          </div>
          <SmallCar ref="smallCarRef" v-if="truckData.endAddressPoint" :endCity="truckData.endAddressPoint.city"
            @confirmLoad="confirmChoose" />
        </div>
        <!-- 运输线路详情 -->
        <div v-show="truckData.truckStatus?.value === 2">
          <div class="content-title">运输线路详情</div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="5">
                <span>总运输里程（Km）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.totalTransportKm }}</span>
              </a-col>
              <a-col :span="5">
                <span>实际剩余公里数（Km）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.remainingKm }}</span>
              </a-col>
              <a-col :span="4">
                <span>每日应行驶路程</span>
              </a-col>
              <a-col :span="4">
                <span>{{ truckData.gpsLine?.defaultKm }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="5">
                <span>是否超期</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.isOverDue.label }}</span>
              </a-col>
              <a-col :span="5">
                <span>预计需时（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.totalTransportTime }}</span>
              </a-col>
              <a-col :span="4">
                <span>发车时间</span>
              </a-col>
              <a-col :span="4">
                <span>{{ truckData.gpsLine?.departureTime }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="5">
                <span>已行驶时间（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.transportTime }}</span>
              </a-col>
              <a-col :span="5">
                <span>记录时间（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ truckData.gpsLine?.transportTime }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 运输位置 -->
        <div v-show="truckData.truckStatus?.value === 2">
          <div class="content-title" style="display: flex;justify-content: space-between;">
            <div>
              <span style="margin-right:10px" @click="addPositionShow = true">运输位置</span>
              <span style="color:#B5BBC5;font-weight: normal;">共{{ transPosition.length || 0 }}条</span>
            </div>
            <a @click="mapShow = true, createMap()">地图预览</a>
          </div>
          <div class="content-data">
            <a-button type="primary" @click="addPositionShow = true">+新增位置</a-button>
            <div v-show="transPosition.length <= 0" style="text-align: center;color:#6B778C">暂无在途信息</div>
            <a-steps v-show="transPosition.length > 0" progress-dot :current="1" direction="vertical">
              <a-step v-for="(item, index) in transPosition" :key="index">
                <template #title>
                  <div style="display: flex;justify-content: space-between;">
                    <div>
                      <div style="display:flex">
                        {{ item.location }}
                        <a-typography-paragraph :copyable="{ text: item.location }">
                        </a-typography-paragraph>
                      </div>
                    </div>
                    <a v-show="index === 0" @click="positionChange = true, changeAddressData = item">修改</a>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>备注：{{ item.remark }}</div>
                    <div>{{ item.operator }}<span style="margin-left:10px">{{ item.recordTime }}</span></div>
                  </div>
                </template>
              </a-step>
            </a-steps>
          </div>
        </div>
      </div>
      <div v-if="vehiclesId !== '' && vehicleList.length > 0" class="right" style="flex:1">
        <Record :vehicleList="vehicleList" :vehiclesId="vehiclesId" :employeeRealList="employeeList" :orderId="orderId" />
      </div>
    </div>
  </a-modal>
  <!-- 更改GPS类型 -->
  <a-modal v-model:visible="changeGPSshow" title="修改GPS类型" @ok="confirmChange" :confirmLoading="btnLoading">
    <div>
      GPS类型：
      <a-select v-model:value="GPStype" style="width: 120px">
        <a-select-option :value="1">智运GPS</a-select-option>
        <a-select-option :value="2">超越GPS</a-select-option>
        <a-select-option :value="3">关闭GPS</a-select-option>
        <a-select-option :value="4">途强GPS</a-select-option>
      </a-select>
    </div>
  </a-modal>
  <!-- 承运商运单发送 -->
  <a-modal v-model:visible="sendCarrierShow" title="承运商运单发送" @ok="sendCarrierShow = false" :width="800"
    :destroyOnClose="true">
    <SendCarrier :transTruckId="transTruckId" :lineName="truckData.lineName" />
  </a-modal>
  <!-- 输入实际收款方式(发车) -->
  <a-modal v-model:visible="receivables" title="填写收款方式" @ok="startCar" :confirmLoading="btnLoading">
    <h1>总金额<span style="color:#f00">{{ truckData.freightCost }}</span></h1>
    <a-input addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large">
      <template #suffix>
        <a-tooltip title="全部油卡">
          <EnterOutlined @click="driver.oilCard = truckData.freightCost, driver.bankCard = 0"
            style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template>
    </a-input>
    <a-divider type="vertical" />
    <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large">
      <template #suffix>
        <a-tooltip title="全部转账">
          <EnterOutlined @click="driver.bankCard = truckData.freightCost, driver.oilCard = 0"
            style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template>
    </a-input>
    <!--    <a-input addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large"/>-->
    <!--    <a-divider type="vertical"/>-->
    <!--    <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large"/>-->
  </a-modal>
  <!-- 中途到达 中转点选择 -->
  <a-modal v-model:visible="transitShow" title="请选择卸车地点" @ok="transitOperation" :confirmLoading="btnLoading">
    <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择卸车地点" style="width: 100%"
      @change="transitAddress" />
  </a-modal>
  <!-- 批量卸车 -->
  <a-modal v-model:visible="batchTruckShow" title="请选择卸车地点" @ok="batchTruckOperation" :confirmLoading="btnLoading">
    <b>只满足于一板车装车人和操作人一致的情况</b>
    <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择卸车地点" style="width: 100%"
      @change="transitAddress" />
  </a-modal>
  <!-- 退板 -->
  <a-modal v-model:visible="backVisible" title="退小板-原因" :zIndex="1110" :maskClosable='false' @ok="quitPlan"
    :confirmLoading="btnLoading">
    <a-input addon-before="原因" v-model:value="reason" size="large" />
  </a-modal>
  <!-- 更换大车 -->
  <a-modal v-model:visible="changeShow" :width="800" title="更改大车" footer="">
    <div>
      <BigCarDriver :carrierId="truckData.carrierId" @carDriverData="carDriverData" />
    </div>
  </a-modal>
  <!-- 更改线路及承运商 -->
  <a-modal v-model:visible="changeLineData" :width="800" title="更改线路及承运商" footer="" :destroyOnClose="true"
    @cancel="loadData" @ok="changeLineData = false">
    <div>
      <p>
        <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
          @change="setStartAddress" placeholder="请输入起点" />
        ——
        <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
          @change="setEndAddress" placeholder="请输入终点" />
        <a @click="searchLine(), linePage.current = 1">搜索</a>
      </p>
      <div v-show="lineShow">
        <div class="line-list font-color2" v-for="(item, index) in lineList" :key="index" @click="confirmLine(item)">
          <span>{{ item.name }}</span>
          <a class="confirm">确认选择修改</a>
        </div>
      </div>
      <a-skeleton v-show="lineLoading" active :paragraph="{ rows: 5 }" />
      <div v-show="lineList.length > 0 && lineShow">
        <a-pagination size="small" :current="linePage.current" :total="linePage.total" :pageSize="linePage.pageSize"
          @change="lineListChange" />
      </div>
      <h2 v-show="lineList.length <= 0 && !lineLoading">暂无数据</h2>
      <div v-show="!lineLoading && addShow"><a @click="addLine">点击添加路线</a></div>
    </div>
    <Carrier ref="carrierRef" v-if="carrierShow" :carrierList="carrierList" @confirm="chooseCarrier" />
  </a-modal>
  <!-- 选择司机 -->
  <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false" footer="">
    <Driver ref="driverRef" :carrierId="carrierId" @onondriverName="chooseDriver" />
  </a-modal>
  <!-- 小车详情 -->
  <a-modal v-model:visible="smallDetailShow" footer="" width="65%"
  :bodyStyle="{ padding: '15px', paddingTop: 0 }">
    <template #title>
      <WindowDetail v-if="smallDetail.orderVehicleId" :detailData="smallDetail"/>
    </template>
    <div class="left-content" style="width:auto">
      <TransportModel ref="transportModelRef" :windowShow="smallDetailShow" :data="smallDetail" :detailType="true"
        @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
  <!--修改最近一条位置信息-->
  <a-modal v-model:visible="positionChange" title="修改位置信息" :confirm-loading="confirmLoading" @ok="confirmChangeLocation">
    <div>
      (原) {{ changeAddressData.location }}——>>
      <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }"
        @change="setChangeAddress" placeholder="请选择地址" />
      <a-input style="margin-top:10px" v-model:value="formNowLocation.details" placeholder="详细地址" />
    </div>
  </a-modal>
  <!-- 新增位置 -->
  <a-modal v-model:visible="addPositionShow" title="新增位置" @ok="confirmAdd" :confirmLoading="btnLoading">
    <div>
      <div style="margin: 15px 0">所在地区<span style="color:#f00">*</span></div>
      <a-cascader :options="$store.state.app.cityDict" v-model:value="address" :changeOnSelect="true"
        :show-search="{ filter }" @change="setAddress" placeholder="请选择地址" />
      <div style="margin: 15px 0">详细地址</div>
      <a-input v-model:value="formNowLocation.details" placeholder="详细地址" />
    </div>
  </a-modal>
  <!-- 地图路线预览 -->
  <a-modal v-model:visible="mapShow" title="地图预览" width="40%" footer="">
    <div v-show="mapLoadShow" style="text-align: center;"> <a-spin size="large" /></div>
    <div v-show="!mapLoadShow" id="containerMap"></div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref, watch } from 'vue'
import { EnterOutlined } from '@ant-design/icons-vue'
import { add as lineAdd } from '@/api/trans/line'
import { message } from 'ant-design-vue'
import {
  transPlan,
  transDepart,
  detail,
  truckGpsPage,
  transfer,
  changeGPStype,
  transArrive,
  transCancel,
  changeStore,
  pitTruck,
  tracking,
  truckGpsAdd,
  changeCarrier,
  changeDriver
} from '@/api/transport/truck'
import { carUnloadVehicle, changeTransType } from '@/api/transport/transport'
import { linePage, getCarrier } from '@/api/transport/transportUse'
import { transEdit } from '@/api/trans/ops/edit'
import { copyText } from '@/utils/util'
import { vehicleId, changeLine, vehicleIdHis } from '@/api/transport/vehicle'
import Record from '../../../transportCapacity/transportUse/comon/record.vue'
import SendCarrier from '../../../transportCapacity/transportUse/comon/carrierSend.vue'
import BigCarDriver from '../comon/bigCarDriver.vue'
import SmallCar from '../../../transportCapacity/transportUse/comon/smallCar.vue'
import Carrier from '../../../transportCapacity/transportUse/comon/carrierChoose.vue'
import Driver from '@/views/components/driver'
import TotalDetail from '../../../transportCapacity/transportUse/detail/totalDetail'
import WindowDetail from '../../vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

export default {
  components: {
    Record,
    SendCarrier,
    BigCarDriver,
    EnterOutlined,
    TotalDetail,
    SmallCar,
    Carrier,
    Driver,
    WindowDetail,
    TransportModel
  },
  props: {
    windowShow: Boolean,
    transTruckId: String,
    pageIndex: Number,
    reload: Function
  },
  setup (props, context) {
    const driverRef = ref(null)
    const smallCarRef = ref(null)
    const carrierRef = ref(null)
    const transportModelRef =  ref(null)
    const state = reactive({
      vinNo: '',
      reason: '',
      pageIndex: '',
      windowShow: false,
      changeGPSshow: false,
      btnLoading: false,
      license: true,
      addShow: false,
      mapShow: false,
      mapLoadShow: false,
      confirmLoading: false,
      positionChange: false,
      addPositionShow: false,
      carrierShow: false,
      changeShow: false,
      backVisible: false,
      deiverShow: false,
      sendCarrierShow: false,
      changeLineData: false,
      transitShow: false,
      batchTruckShow: false,
      smallDetailShow: false,
      loading: false,
      lineShow: false,
      lineLoading: false,
      receivables: false,
      arrangedLoading: false,
      map: null,
      GPStype: null,
      markers: [],
      historyList: [],
      vehicleList: [],
      lineList: [],
      transPosition: [],
      carrierList: [],
      smallDetail: {},
      startAddress: {},
      endAddress: {},
      transTruckId: '',
      vehiclesId: '',
      address: '',
      employeeList: [],
      planVehicles: [],
      arrangedData: {},
      transit: {},
      element: {},
      record: {},
      transOrderVehicleId: [],
      changeAddressData: {},
      linePage: {
        total: 0,
        current: 1,
        pageSize: 5
      },
      formNowLocation: {
        details: ''
      },
      truckData: {
        truckStatus: {
          label: '',
          value: ''
        }
      },
      driver: {
        oilCard: '',
        bankCard: ''
      },
      columns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '取送车信息',
          width: '40%',
          dataIndex: 'options',
          slots: {
            customRender: 'options'
          }
        },
        {
          title: '是否代收运费',
          dataIndex: 'substitute',
          slots: {
            customRender: 'substitute'
          }
        }
      ],
      arrangedColumns: [
        {
          title: '车牌号',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '车型',
          dataIndex: 'brand',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '单台价格',
          dataIndex: 'transportFee'
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator'
        },
        {
          title: '起始地',
          dataIndex: 'address',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '当前状态',
          dataIndex: 'transportType.label'
        },
        {
          title: '到达门店',
          dataIndex: 'store',
          slots: {
            customRender: 'store'
          }
        },
        {
          title: '库存信息',
          dataIndex: 'stockType',
          slots: {
            customRender: 'stockType'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      historyColumns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          width: '13%',
          align: 'center'
        },
        {
          title: '车型',
          dataIndex: 'brand',
          width: '13%',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '单台价格',
          dataIndex: 'transportFee',
          width: '13%',
          align: 'center'
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          width: '13%',
          align: 'center'
        },
        {
          title: '运输线路',
          dataIndex: 'address',
          width: '15%',
          align: 'left',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '当前状态',
          dataIndex: 'transportType.label',
          width: '13%',
          align: 'center'
        }
      ],
      BIGArrivecolumns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          key: 'carNo',
          ellipsis: true
        },
        {
          title: '派车人',
          dataIndex: 'dispatcher',
          key: 'dispatcher',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '大车司机',
          dataIndex: 'driverName',
          key: 'driverName',
          ellipsis: true
        },
        {
          title: '司机电话',
          dataIndex: 'driverTel',
          key: 'driverTel',
          ellipsis: true
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          key: 'truckStatus.label',
          ellipsis: true
        },
        {
          title: '使用车位',
          dataIndex: 'usedParkPlace',
          key: 'usedParkPlace',
          ellipsis: true
        },
        {
          title: '线路终点',
          dataIndex: 'lineName',
          key: 'lineName',
          ellipsis: true,
          slots: {
            customRender: 'lineName'
          }
        }
      ],
      ArrangedPagination: {
        current: 1,
        pageSize: 20,
        pageSizeOptions: ['5', '10', '20', '40'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 自定义搜索线路
    const searchLine = () => {
      state.lineLoading = true
      linePage({ // 获取路线
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startCityId: state.startAddress.city,
        endCityId: state.endAddress.city
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
          state.lineShow = true
          if (res.data.records.length <= 0) state.addShow = true
          else state.addShow = false
        }
      })
    }
    const lookDetail = (record) => {
      state.smallDetail = record
      state.smallDetailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const transportModelShowStatus = e =>{
      if(e.orderId) state.smallDetail = e
    }
    // 搜索线路
    const setStartAddress = (value, selectedOptions) => {
      state.startAddress.province = value[0]
      state.startAddress.city = Number(value[1])
      state.startAddress.area = value[2]
    }
    // 自动添加线路
    const addLine = () => {
      lineAdd({
        transLine: {
          type: 1,
          isEnable: 1,
          startAddress: state.startAddress,
          endAddress: state.endAddress
        }
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          searchLine()
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const setEndAddress = (value, selectedOptions) => {
      state.endAddress.province = value[0]
      state.endAddress.city = Number(value[1])
      state.endAddress.area = value[2]
    }
    // 确认路线
    const confirmLine = item => {
      changeLine(state.transTruckId, item.id)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.lineShow = false
            getDetail()
            // 获取承运商
            getCarrier({
              startCity: state.startAddress.city,
              endCity: state.endAddress.city
            }).then(res => {
              if (res.code === 10000) {
                state.carrierList = res.data
                state.carrierShow = true
              }
            })
          }
        })
    }
    // 确认修改承运商
    const chooseCarrier = e => {
      if (e) {
        changeCarrier(state.transTruckId, e.id)
          .then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              getDetail()
            }
          })
      }
    }
    const arrangedChange = (pag, filters, sorter) => {
      state.ArrangedPagination.current = pag.current
      state.ArrangedPagination.pageSize = pag.pageSize
      getArrangedData(false)
    }
    // 修改司机
    const chooseDriver = recode => {
      if (recode) {
        changeDriver(state.transTruckId, recode.id)
          .then(res => {
            if (res.code === 10000) {
              state.deiverShow = false
              message.success(res.msg)
              getDetail()
            }
          })
      }
    }
    // 中转地
    const transitAddress = (e, v) => {
      state.transit.province = e[0]
      state.transit.city = e[1]
      state.transit.area = e[2]
    }
    // 可安排小车传值接收
    const confirmChoose = e => {
      if (state.truckData.truckStatus.value === 2) {
        pitTruck(state.transTruckId, {
          transit: e.transit,
          freight: e.freight,
          transTruckId: state.transTruckId,
          transOrderVehicleIds: e.transOrderVehicleIds,
          isShop: e.isShop
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getArrangedData(true)
            smallCarRef.value.getSureData()
          }
        })
      } else {
        transPlan({
          transit: e.transit,
          freight: e.freight,
          transTruckId: state.transTruckId,
          transOrderVehicleIds: e.transOrderVehicleIds,
          isShop: e.isShop
        }).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            smallCarRef.value.getSureData()
          }
        })
      }
    }
    // 更改大车
    const carDriverData = e => {
      if (e) {
        changeTransType(state.transTruckId, e.carrierTruckId, e.id) // e.id为司机ID
          .then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              loadData()
              state.changeShow = false
            }
          })
      }
    }
    // 发车
    const startCar = () => {
      if (Number(state.driver.oilCard) + Number(state.driver.bankCard) === Number(state.truckData.freightCost)) {
        state.btnLoading = true
        transDepart(state.transTruckId, state.driver).then(res => {
          if (res.code === 10000) {
            state.receivables = false
            message.success(res.msg)
            loadData()
          }
        }).finally(() => { state.btnLoading = false })
      } else {
        message.error('金额填写数目错误,请查看')
      }
    }
    // 到达
    // const confirmArrival = () => {
    //   transArrive(state.transTruckId, {})
    //     .then(res => {
    //       if (res.code === 10000) {
    //         message.success(res.msg)
    //         loadData()
    //       }
    //     })
    // }
    // 中途到达
    const transitOperation = () => {
      if (state.transit.city) {
        state.btnLoading = true
        transfer(state.transTruckId, {
          totvId: state.transOrderVehicleId[0],
          stopAddress: state.transit,
          transTruckId: state.transTruckId,
          transOrderVehicleIds: state.transOrderVehicleId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.transitShow = false
            getArrangedData(true)
          }
        }).finally(() => { state.btnLoading = false })
      } else message.error('请选择中转地')
    }
    // 批量卸车
    const batchTruckOperation = () => {
      if (state.transit.city) {
        state.btnLoading = true
        transArrive(state.transTruckId, {
          stopAddress: state.transit
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.batchTruckShow = false
            getArrangedData(true)
          }
        }).finally(() => { state.btnLoading = false })
      } else message.error('请选择中转地')
    }
    // 取消任务
    const cancelPlan = () => {
      transCancel(state.transTruckId, {}).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          props.reload(state.pageIndex)
        }
      })
    }
    // 取消装车2
    const cancel = item => {
      carUnloadVehicle({ ids: [item.transOrderTruckVehicleId] })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            smallCarRef.value.getSureData()
          }
        })
    }
    // 退板
    const backCar = item => {
      state.record = item
      state.backVisible = true
    }
    // 确认退板
    const quitPlan = () => {
      state.btnLoading = true
      transEdit({
        params: {
          transportTruckId: state.transTruckId,
          totvIds: [state.record.transOrderTruckVehicleId]
        },
        type: {
          value: 1
        },
        reason: state.reason,
        subType: 100
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.reason = ''
          state.backVisible = false
          getArrangedData(true)
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 获取已安排车信息 flag 为true时，表示需要刷新数据
    const getArrangedData = (flag) => {
      state.arrangedLoading = true
      vehicleId(state.transTruckId, ({
        current: state.ArrangedPagination.current,
        size: state.ArrangedPagination.pageSize
      })).then(res => {
        if (res.code === 10000) {
          state.vehicleList = []
          res.data.records.forEach(item => {
            if (item.isShop === null) {
              item.isShop = {
                label: '否',
                value: 0
              }
            }
            state.vehicleList.push({
              id: item.orderVehicleId,
              vinNo: item.vinNo
            })
          })
          state.planVehicles = res.data.records
          if (res.data.records.length > 0) {
            state.vehiclesId = res.data.records[0].orderVehicleId // 传入消息发送组件 默认第一辆车ID
          } else {
            if (state.truckData.truckStatus?.value === 2 && flag) {
              getDetail()
            }
          }
          state.arrangedLoading = false
        }
      })
    }
    // 是否到达修改
    const isShopChange = record => {
      if (record.isShop.value === 0) {
        record.isShop.label = '否'
      } else {
        record.isShop.label = '是'
      }
      changeStore({
        isShop: record.isShop,
        transOrderTruckVehicleId: record.transOrderTruckVehicleId
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getArrangedData()
        }
      })
    }
    const loadData = () => {
      getDetail()
    }
    // 获取详情-详细数据
    const getDetail = () => {
      detail(state.transTruckId)
        .then(res => {
          if (res.code === 10000) {
            state.truckData = res.data
            state.transTruckId = res.data.transTruckId
            state.truckData.endAddressPoint.city = Number(state.truckData.endAddressPoint.city)
            if (res.data.truckStatus?.value === 4) getHistoryData()
            // if (state.truckData.truckStatus.value >= 2) state.license = false
            // else state.license = true
            state.license = true
            if (state.truckData.truckStatus.value >= 1) getArrangedData(false)
            getLocation()
          }
        })
    }
    // 历史装运车
    const getHistoryData = () => {
      state.loading = true
      vehicleIdHis(
        state.transTruckId, ({
          current: 1,
          size: 999999
        })
      ).then(res => {
        if (res.code === 10000) {
          state.vehicleList = []
          state.historyList = res.data.records
          state.historyList.forEach(item => {
            state.vehicleList.push({
              id: item.orderVehicleId,
              vinNo: item.vinNo
            })
          })
        }
      }).finally(() => {
        state.loading = false
      })
    }
    // 运输位置
    const getLocation = () => {
      truckGpsPage(state.transTruckId, {}).then(res => {
        if (res.code === 10000) {
          state.transPosition = res.data.records
        }
      })
    }
    // 修改最近一次运输位置信息
    const confirmChangeLocation = () => {
      state.confirmLoading = true
      const data = JSON.parse((JSON.stringify((state.formNowLocation))))
      delete data.details
      truckGpsAdd({
        addressDTO: data,
        gpsId: state.changeAddressData.gpsId,
        details: state.formNowLocation.details,
        remark: '手动修改',
        transTruckId: state.transTruckId
      }).then(res => {
        if (res.code === 10000) {
          message.success('修改成功')
          getLocation()
          state.positionChange = false
          state.confirmLoading = false
        }
      })
    }
    const setChangeAddress = (e, v) => {
      state.formNowLocation.province = e[0]
      state.formNowLocation.city = e[1]
      state.formNowLocation.area = e[2]
      if (e[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    const setAddress = (value) => {
      state.formNowLocation.province = value[0]
      state.formNowLocation.city = value[1]
      state.formNowLocation.area = value[2]
      if (value[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    // 确认新增位置
    const confirmAdd = () => {
      var NowLocation = {
        transTruckId: state.transTruckId,
        addressDTO: state.formNowLocation,
        remark: '手动新增'
      }
      if (state.formNowLocation.city) requestAPI()
      else message.error('请选择地区')
      function requestAPI () {
        state.btnLoading = true
        truckGpsAdd(NowLocation).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            state.addPositionShow = false
            getLocation()
            state.address = ''
            state.formNowLocation.details = ''
            context.emit('confirmAdd')
          }
        }).finally(() => { state.btnLoading = false })
      }
    }
    // 创建地图实例
    const createMap = ref => {
      /* eslint-disable */
      state.mapLoadShow = true
      // 创建Map实例
      if (state.map === null) {
        setTimeout(() => {
          state.map = new LKMap.Map('containerMap', {
            center: [109.128713, 31.383405],
            zoom: 4
          })
          state.map.on("load", function () {
            state.mapLoadShow = false
            newTracking()
          })
        }, 500)
      } else newTracking()
    }
    //  车辆轨迹
    const newTracking = () => {
      tracking(state.transTruckId).then((res) => {
        if (res.code === 10000) {
          state.newList = res.data
          let data = res.data
          if (data.length > 0) {
            // console.log('data',data);
            // data[1] = data[0]
            markMap(data)
          } else {
            message.info("该车辆没有位置信息")
            state.mapShow = false
          }
        } else {
          message.error(res.msg)
        }
      })
    }
    // 路书更替
    const markMap = (data) => {
      if (state.markers.length > 0) {
        state.markers.forEach(item => {
          item.remove()
        })
      }
      var drivingService
      drivingService = new LKMap.Driving_v2({
        costing: 'auto',	  // 路线规划方式  auto：轿车
        alternates: 0      //查找至多n条路线（n =< 3）
      })
      let coordinate = []
      data.forEach(item => {
        coordinate.push({ lat: Number(item.lat), lon: Number(item.lon) })
      })
      // 路线数组 data
      setTimeout(() => {
        var params = {
          // locations: [{ lon: coordinate[0].lon, lat: coordinate[0].lat }, { lon: coordinate[coordinate.length - 1].lon, lat: coordinate[coordinate.length - 1].lat }],
          locations: coordinate,
          costing: 'auto',	  // 路线规划方式  auto：轿车
          alternates: 0      //查找至多n条路线（n =< 3）
        }
        drivingService.search(params, (status, result) => {
          showLine(result)	// 绘制线路
          addIcon()	// 添加起点坐标
        })
      }, 100)
      // 添加起点终点
      function addIcon () {
        state.markers = [];
        for (var i in coordinate) {
          var anchor = "center";
          var icon = new LKMap.Icon({
            size: new LKMap.Size(30, 30),
            image: 'https://lkimgyt.luokuang.com/1612506398655.png',
            imageSize: new LKMap.Size(30, 30),
            // imageOffset: new LKMap.Pixel(0, 0)
          });
          if (i == 0) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232132.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          } else if (i == coordinate.length - 1) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232633.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          }
          var position = new LKMap.LngLat(coordinate[i]['lon'], coordinate[i]['lat'])
          // var inforWindow = new LKMap.InforWindow({
          //   anchor: 'bottom',
          //   content: '<h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p>',
          //   position: position,
          // });
          // inforWindow.open(map, position);
          state.markers[i] = new LKMap.Marker({
            position: position,
            icon: icon,
            label: {
              // item.gpsTime + '</br>' + '已到达' + item.latestLocation
              content: data[i]['gpsTime'] + '<br/>' + data[i]['latestLocation']
            },
            anchor: 'bottom',
          })
          state.markers[i].setMap(state.map);
          // state.markers[i].setContent("<div class='marker-demo'><h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p></div>")
        }
        state.mapLoadShow = false
      }

      // 绘制线路
      function showLine (result) {
        var polyline = []
        var color = ['#a9a4a4', '#3471e2', '#e44d33'];
        for (var i in result.routes) {
          polyline[i] = new LKMap.Polyline({
            path: result.routes[i].geometry.coordinates,
            strokeWeight: 8,
            lineJoin: 'round',
            lineCap: 'round',
            strokeColor: '#3471e2',
            showDir: true,
          });
          polyline[i].setMap(state.map);
        }
        setTimeout(() => {
          state.map.setFitView(null, {
            padding: {
              top: 100,
              bottom: 100,
              left: 0,
              right: 0,
            }
          });
        }, 500)
      }
    }
    const getPassword = () => {
      copyText(state.truckData.carCollectionPassword)
      message.success('出库密码已复制')
    }
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = newVal
      if (props.transTruckId) state.transTruckId = props.transTruckId
      if (props.pageIndex) state.pageIndex = props.pageIndex
      state.vehicleDetail = props.vehicleDetail
      if (state.windowShow === true) loadData()
    })
    const cancelClose = () => {
      state.windowShow = false
      state.truckData = {}
      state.planVehicles = []
      clearData()
      context.emit('statusShow', state.windowShow)
    }
    // 确认修改GPS类型
    const confirmChange = () => {
      if (state.GPStype !== '') {
        state.btnLoading = true
        changeGPStype({
          gpsType: state.GPStype,
          trunkId: state.truckData.carrierTruckId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.GPStype = ''
            state.changeGPSshow = false
            getDetail()
          }
        }).finally(() => { state.btnLoading = false })
      } else message.error('请选择')
    }
    onMounted(() => {
    })
    const clearData = () => {
      state.markers = []
      state.historyList = []
      state.vehicleList = []
      state.lineList = []
      state.transPosition = []
      state.carrierList = []
      state.smallDetail = {}
      state.startAddress = {}
      state.endAddress = {}
      state.transTruckId = ''
      state.vehiclesId = ''
      state.address = ''
      state.employeeList = []
      state.planVehicles = []
      state.arrangedData = {}
      state.transit = {}
      state.element = {}
      state.record = {}
      state.transOrderVehicleId = []
      state.changeAddressData = {}
    }

    return {
      ...toRefs(state),
      carrierRef, // ref
      driverRef, // ref
      smallCarRef, // ref
      addLine,
      backCar,
      quitPlan,
      loadData,
      cancel,
      clearData,
      createMap,
      newTracking,
      getLocation,
      chooseDriver,
      searchLine,
      confirmLine,
      chooseCarrier,
      cancelClose,
      startCar,
      setAddress,
      confirmAdd,
      setChangeAddress,
      confirmChange,
      confirmChangeLocation,
      setStartAddress,
      setEndAddress,
      carDriverData,
      isShopChange,
      cancelPlan,
      getPassword,
      transitAddress,
      transitOperation,
      batchTruckOperation,
      confirmChoose,
      arrangedChange,
      lookDetail,
      transportModelRef,
      transportModelShowStatus
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../transportCapacity/transportUse/index.less';
@import '../index.less';

:deep(.ant-table-body) {
  font-size: 12px !important;
}

:deep(.ant-steps-item-content) {
  width: auto !important;

  .ant-steps-item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ant-steps-item-title>div {
    width: 100%;
  }
}

.data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  div {
    width: 50%;
  }
}

.QRC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.car-data {
  display: flex;
  justify-content: space-around;
}

#containerMap {
  width: 100%;
  height: 300px;
}
</style>
