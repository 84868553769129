<template>
  <div>
    <a-modal :visible="visible" :title="title" centered footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="(item,index) in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          <span v-if="index !== 0"> {{ typeKey }}-</span>{{ item.name }}&nbsp;&nbsp;{{ item.total }}
        </div>
      </div>
      <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div class="m-t1 tableBox">
        <a-table ref="tableRef" size="small" :dataSource="data" :rowKey="item => item.orderVehicleId" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500,x:1800}">
          <template #sort="{ index }">
            {{index+1}}
          </template>
          <template #vinNo="{ record }">
            <div class="flex al-center ju-center">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div class="isExpatriate cur-p" v-if="record.taskId">
                  派
                </div>
              </a-popover>
              <div style="word-break:break-all"> {{ record.vinNo }}</div>
            </div>
            <div> {{ record.brand }}{{ record.model }}</div>
          </template>
          <template #transportNo="{ record }">
            <a @click="openBigCar(record)">{{ record.transportNo }}</a>
          </template>
          <template #startAddress="{ record }">
            {{ record.startAddress}}
            <div>{{ record.endAddress }}</div>
          </template>
          <template #picker="{ record }">
            <div>{{ record.picker }}</div>
            {{ record.pickerMobile }}
          </template>
          <template #consignor="{ record }">
            <div>{{ record.consignor }}</div>
            {{ record.consignorMobile }}
          </template>
          <template #departure="{ record }">
            <div>{{ record.departure }}</div>
            {{ record.departureTime }}
          </template>
          <template #arranger="{ record }">
            <div>{{ record.arranger }}</div>
            {{ record.scheduleTime }}
          </template>
          <template #settlementStatus="{ record }">
            <div>{{ record.orderSettlement?.label }}</div>
            <span :style="{color:colorSettlementStatus[record.settlementStatus?.value]}">{{ record.settlementStatus?.label }} <a v-if="record.settlementStatus?.value !== 2" @click="openPayQRC(record)">
                收款码
              </a></span>
          </template>
          <template #deliveryCard="{ record }">
            <div class="flex" v-if="record.pic?.value === 1">
              <div class="picInfoBox">照片：</div>
              <a @click="lookVehicleImgs(record)">查看</a>
            </div>
            <div style="text-align: left;" v-if="record.address?.value === 1">
              地址：<a @click="lookVehicleGps(record)">查看</a>
              ({{ record.gpsCity}}{{ record.gpsTime}})
            </div>
            <div class="flex" v-if="record.deliveryCard?.value === 1">
              <div class="picInfoBox">交车条：</div>
              <a @click="lookDeliveryCard(record)">查看</a>
            </div>
            <div v-if="record.taskStartTime" style="text-align: left;">
              发运时间：{{ record.taskStartTime}}
            </div>
          </template>
          <template #dispatchRemark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.dispatchId">
              <div class="remark-content">
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.dispatchRemark }}</span>
                  </template>
                  <span>{{ record.dispatchRemark }}</span>
                </a-tooltip>
                <a v-show="!record.dispatchRemark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.dispatchRemark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
          <template #custom="{ record }">
            <a>小程序码</a>
            <a class="m-l1">司机信息</a>
          </template>
          <template #add="{ record }">
            <a @click="model(record)">操作</a>
          </template>
        </a-table>
      </div>
      <!-- 小车详情 -->
      <SmallVehDetails @modelLoadData="modelLoadData" ref="smallVehDetailsRef"></SmallVehDetails>
      <!-- 新增/编辑调度备注 -->
      <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId" />
      <!-- 大车操作 -->
      <LoadingVehilce :windowShow="loadingVehShow" :transTruckId="detailData.transportTruckId" @statusShow="loadingVeh" />
      <!-- 支付二维码 -->
      <PayQRC ref="payQRCref" />
      <!-- 车辆照片 -->
      <VehicleImg ref="vehicleImgRefs" />
      <!-- 在途位置 -->
      <VehicleGps ref="vehicleGpss" />
      <!-- 交车条 -->
      <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import LoadingVehilce from '@/views/components/transport/bigVehicleOperate/loadingVehilce.vue'
import SchedulingLogs from '@/views/components/transport/smallVehicleOperate/schedulingLogs.vue'
import SmallVehDetails from '@/components/smallVehDetails'
import { useStore } from 'vuex'
import PayQRC from '@/views/components/payQRC'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'
import { taskDeliveryPic } from '@/api/transport/transport'
import { IfNull } from '@/utils/util'


const store = useStore()
const emit = defineEmits(['update:visible', 'searchAdd', 'resetBtn', 'setRemaekOk'])
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  typeKey: {
    type: String,
    default: ''
  },
  iptData: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  colorIdx: {
    type: Number,
    default: null
  },
  info: {
    type: Object,
    default: {}
  }
})
const cancel = () => {
  emit('update:visible', false)
}
const lookImgID = ref([])
const visibleLookDeliver = ref(null)
const vehicleGpss = ref(null)
const vehicleImgRefs = ref(null)
const payQRCref = ref(null)
const loadingVehShow = ref(false)
const detailData = ref({})
const indexKey = ref(null)
const smallVehDetailsRef = ref(null)
const remarkId = ref('')
const isOverTime = reactive({
  isOverTime: '',
  transportType: '',
  taskType: '',
})
const logsVehicleId = ref('')
const remarkShow = ref(false)
const colorSettlementStatus = {
  0: '#D20509',
  2: '#15AD31'
}

// 查看交车条
const lookDeliveryCard = (record) => {
  taskDeliveryPic(record.orderVehicleId, {
    taskId: record.taskId
  }).then(res => {
    if (res.code !== 10000) return
    lookImgID.value = res.data.map(item => item.fileId)
    visibleLookDeliver.value = true
  })
}
// 查看视频图片
const lookVehicleImgs = (record) => {
  setTimeout(() => {
    vehicleImgRefs.value.vehicleImg(record.orderVehicleId, record.vinNo)
  }, 50)
}
// 查看位置
const lookVehicleGps = (record) => {
  setTimeout(() => {
    vehicleGpss.value.onvisible(true)
    vehicleGpss.value.onorderVehicleId(record.orderVehicleId)
  }, 50)
}
//收款码
const openPayQRC = (record) => {
  let obj = {
    startAddress: record.startAddress,
    endAddress: record.endAddress,
    orderId: record.orderId,
    ovId: record.orderVehicleId,
    vehicleList: [{ vinNo: record.vinNo }]
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}
const modelLoadData = (time, details) => {
  if (time) {
    props.data.forEach(opt => {
      if (details.orderVehicleId === opt.orderVehicleId) {
        opt.preDepartureTime = time
      }
    })
    console.log( props.data);
  }
}
const openBigCar = (record) => {
  loadingVehShow.value = true
  detailData.value = record
}
const loadingVeh = () => {
  loadingVehShow.value = false
}
const setRemaekOk = (str, label) => {
  if (IfNull(str)) {
    props.data.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.dispatchRemark = str;
      }
    })
  }
  if (label) {
    props.data.forEach(item => {
      if (item.dispatchId == remarkId.value) {
        item.labelName = label;
      }
    })
  }
}
const searchAdd = (e) => {
  emit('searchAdd', e)
}
const resetBtn = () => {
  emit('resetBtn')
}
const changeBtn = (e) => {
  indexKey.value = e.colorType
  emit('changeBtn', e.colorType)
}
const model = e => {
  smallVehDetailsRef.value.detail = e

  smallVehDetailsRef.value.windowShow = true
  setTimeout(() => {
    smallVehDetailsRef.value.onOk()
  }, 100)
}
const openDisRemarks = (record) => {
  isOverTime.isOverTime = record.isOverTime.value
  isOverTime.transportType = record.transportType.value
  isOverTime.taskType = record.taskType
  remarkShow.value = true
  logsVehicleId.value = record.orderVehicleId
}
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: 0,
    colorType: null
  },
])
function addEvent (element, eventType, callback) {
  if (element.addEventListener) {
    element.addEventListener(eventType, callback, false);
  } else if (element.attachEvent) {  // 兼容IE8及以下
    element.attachEvent('on' + eventType, callback);
  } else {
    element['on' + eventType] = callback;  // 兼容IE使用旧式方法
  }
}

const tableRef = ref(null)
onMounted(() => {
  if (props.info) {
    tabstatus.value[0].total = props.info.num
    if (props.info.subItemList) {
      props.info.subItemList.forEach((opt) => {
        let msg = {
          name: opt.title,
          total: opt.num,
          colorType: opt.colorType
        }
        tabstatus.value.push(msg)
      })
    }
  }
  props.iptData.forEach(item => {
    if (item.prop === 'labelId') {
      item.opt = store.state.app.labelList
    }
  })
  indexKey.value = props.colorIdx
  nextTick(() => {
    setTimeout(() => {
      const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
      addEvent(tableBody, 'scroll', (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          // 滚动条触底，执行加载数据的操作
          emit('theBottom')
        }
      })
    }, 1000)
  })

})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>