<template>
  <div>
    <a-modal :visible="visible" @cancel="close" footer="" title="颜色配置">
      <div v-for=" (item,index) in data" :key="item.id" class="m-b1" >
       {{ index+1 }}. {{ item.title }}：
          <color-picker  v-model:pureColor="item.back" @pureColorChange="change()"  v-model:gradientColor="gradientColor">
          </color-picker>
      </div>
    
    </a-modal>
  
  </div>
</template>

<script setup>
import { cloneDeep } from 'lodash-es';
import { ref,watch } from 'vue'
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
const emit = defineEmits(['update:visible','submit'])
const props = defineProps({
  visible:{
    type:Boolean,
    default:false
  },
  data:{
    type:Array,
    default:[]
  }
})

const gradientColor = ref("linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)");
const change = () => {
  let msg = cloneDeep(props.data)
  localStorage.setItem('colorData', JSON.stringify(msg))
}

const close = () => {
emit('update:visible', false)
}

</script>

<style lang="less" scoped>
</style>