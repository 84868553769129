<template>
  <div>
    <div :class="changeSty ? 'chengeStyle' : ''">
      <a-form ref="formRef" :model="fromOptions" :rules="rulesOpts">
        <div v-for="item in fromList" :key="item.id">
          <a-form-item :label="item.label" :name="item.name" :class="item.tips ? 'tips' : ''">
            <div v-if="item.type === 'input'">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <a-input v-model:value="fromOptions[item.prop]" :suffix="item.unit" :placeholder="item.placeholder" />
              </div>
            </div>
            <!-- 单选 -->
            <div v-if="item.type === 'select'">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <a-select v-model:value="fromOptions[item.prop]" :placeholder="item.placeholder">
                  <a-select-option v-for="opt in item.opt" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
                </a-select>
              </div>
            </div>
            <!-- 地址 -->
            <template v-if="item.type == 'address'">
              <a-cascader v-model:value="fromOptions[item.prop]" :change-on-select="item.changeOn" :options="$store.state.app.cityDict" show-search  :placeholder="item.placeholder" />
            </template>
            <!-- 搜索框 -->
            <div v-if="item.type === 'selectSearch'">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <a-select :disabled="item.disabled" @select="select" v-model:value="fromOptions[item.prop]" show-search :placeholder="item.placeholder" :not-found-content="null" @search="search">
                  <a-select-option v-for="opt in item.opt" :key="opt.value" :title="opt.value" :value="opt.label">{{ opt.label }}</a-select-option>
                </a-select>
              </div>
            </div>
            <!-- 搜索框多选 -->
            <div v-if="item.type === 'selectSearchMore'">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <a-select v-model:value="item.cleOpt" @change="selectMore" :placeholder="item.placeholder" mode="multiple" :not-found-content="null" @search="searchMore">
                  <a-select-option v-for="opt in item.opt" :key="opt.value" :title="opt.value" :value="opt.label">{{ opt.label }}</a-select-option>
                </a-select>
              </div>
            </div>
            <!-- 多选 -->
            <div v-if="item.type === 'tag'">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <div class="flex tagBox al-center">
                  <div class="flex  wrap">
                    <div class="tag" v-for="(opt, idx) in item.opt" :key="opt.id">
                      {{ opt }}
                      <CloseOutlined style="font-size: 12px" @click="addClose(item, idx)" />
                    </div>
                  </div>
                  <div>
                    <a-input v-model:value="fromOptions[item.prop]" :placeholder="item.placeholder" @keyup.enter.native="handleChange(item, fromOptions[item.prop])" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.type === 'mulSelect'" class="flex">
              <div :style="{ width: item.width ? item.width : '33%' }">
                <a-select v-model:value="fromOptions[item.prop1]" @change="seleAdd1(fromOptions[item.prop1], item)" :placeholder="item.placeholder1">
                  <a-select-option v-for="opt in item.opt1" :key="opt.id" :value="opt.label">{{ opt.label }}</a-select-option>
                </a-select>
              </div>
              <div :style="{ width: item.width ? item.width : '33%' }" class="m-l1">
                <a-select v-model:value="fromOptions[item.prop2]" v-if="item.opt2.length > 0" :placeholder="item.placeholder2">
                  <a-select-option v-for="opt in item.opt2" :key="opt.id" :value="opt.label">{{ opt.label }}</a-select-option>
                </a-select>
              </div>
              <div :style="{ width: item.width ? item.width : '33%' }" class="m-l1">
                <a-select v-model:value="fromOptions[item.prop3]" v-if="item.isHide3" :placeholder="item.placeholder3">
                  <a-select-option v-for="opt in item.opt3" :key="opt.id" :value="opt.label">{{ opt.label }}</a-select-option>
                </a-select>
              </div>
            </div>
            <div v-if="item.type === 'textarea'">
              <a-textarea class="textarea" :rows=" item.rows || 12" :placeholder="item.placeholder" v-model:value="fromOptions[item.prop]" />
            </div>
            <div v-if="item.type === 'upload'">
              <a-upload multiple class="upload" @preview="handlePreview" v-model:file-list="fromOptions[item.prop]" :remove="handleRemove" list-type="picture-card" :before-upload="beforeUpload">
                <div v-if="fromOptions[item.prop].length<5">
                  <plus-outlined></plus-outlined>
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
              <div v-if="state.fileList.length > 0" style="display: flex; align-items: flex-end; margin-bottom: 8px">
                <a-button :loading="state.fileLoading" @click="handleChange1">请点击上传保存</a-button>
              </div>
            </div>
          </a-form-item>
        </div>
      </a-form>
      <slot name="custom"></slot>
    </div>
    <div class="m-t2 m-b2" v-if="isBtn">
      <a-button @click="submit" type="primary">{{ btnText }}</a-button>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import { CloseOutlined, plusOutlined } from "@ant-design/icons-vue";
import { fileUpload as upload } from '@/api/marketing/modList'
import { message } from "ant-design-vue";
const props = defineProps({
  btnText: {
    type: String,
    default:'确定',
  },
  fromList: {
    type: Array,
    default: [],
  },
  changeSty: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "40%",
  },
  destroyOnClose: {
    type: Boolean,
    default: false,
  },
  isBtn: {
    type: Boolean,
    default: false,
  }
});

const previewVisible = ref(false)
const previewImage = ref("")
const state = reactive({
  fileList: [],
  fileLoading: false,
  addForm: {
    fileIds: []
  },
})

const handlePreview = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj))
  }
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
};
// 选中图片
const beforeUpload = (file) => {
  state.fileList.push(file)
  return false
}
// 图片上传
const handleChange1 = () => {
  const len = state.fileList.length
  state.fileLoading = true
  updateBatch(0, len, state.fileList)
}
// 图片上传
const updateBatch = (index, len, imageList) => {
  if (index <= len - 1) {
    const formData = new FormData()
    formData.append('file', imageList[index])
    upload(formData).then((res) => {
      if (res.code === 10000) {
        state.addForm.fileIds.push(res.data)
        // data.fileUrl = res.data.previewUrl
        if (index === len - 1) {
          setTimeout(() => {
            state.fileList = []
            message.success('图片上传成功')
            state.allowAdd = true
            state.fileLoading = false
          }, 1000)
        } else {
          updateBatch(++index, len, imageList)
        }
      }
    })
  } else {
    return false
  }
}
// 图片移除
const handleRemove = (file) => {
  if (!file.originFileObj) return
  const index = state.fileList.indexOf(file)
  const newfileList = state.fileList.slice()
  newfileList.splice(index, 1)
  state.fileList = newfileList
  state.addForm.fileIds.forEach((item, index) => {
    if (item.name === file.originFileObj.name) state.addForm.fileIds.splice(index, 1)
  })
}
const emit = defineEmits([
  "getVal",
  "seleChange1",
  "handleChange",
  "addClose",
  "search",
  "select",
  "searchMore",
  "selectMore",
]);


const seleAdd1 = (val, item) => {
  fromOptions.value[item.prop2] = null;
  emit("seleChange1", val);
};
const formRef = ref(null);
const submit = () => {
  if (state.fileList.length > 0) {
    message.warn('请点击上传保存')
    return
  }
  if (state.fileLoading) {
    message.warn('请等待图片上传完成')
    return
  }
  if (fromOptions.value.upload) {
    fromOptions.value.upload.forEach(item => {
      if (!includesObject(state.addForm.fileIds, item) && item.id) {
        state.addForm.fileIds.unshift(item)
      }
    })
  }
  emit("getVal", fromOptions.value, state.addForm);
};
//验证规则数据处理
const fromOptions = ref({});
const rulesOpts = ref({});
const setValue = () => {
  let option = {};
  let rules = {};
  props.fromList.map((item) => {
    if (item.prop1) {
      rules[item.prop1] = item.rules1;
      option[item.prop1] = item.value1;
    }
    if (item.prop2) {
      rules[item.prop2] = item.rules2;
      option[item.prop2] = item.value2;
    } else {
      rules[item.prop] = item.rules;
      option[item.prop] = item.value;
    }
  });
  fromOptions.value = option;
  rulesOpts.value = rules;
  state.addForm.fileIds = []
};
//判断数组里面是否包含传的对象
function includesObject (arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
      return true;
    }
  }
  return false;
}
// 选择的值
const select = (val, i) => {
  emit("select", i);
};
//多选的值
const selectMore = (val, i) => {
  emit("selectMore", i);
};
//搜索框单选
const search = (val) => {
  emit("search", val);
};
//搜索框多选
const searchMore = (val) => {
  emit("searchMore", val);
};
//多选删除
const addClose = (item, idx) => {
  emit("addClose", item, idx);
};
// 多选输入框改变时间
const handleChange = (item, val) => {
  emit("handleChange", item, val);
  fromOptions.value[item.prop] = "";
};
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const getValRef = () => {
   formRef.value.validate().then(() => {
    submit()
   })
}
defineExpose({
  setValue,
  fromOptions,
  getValRef
})
watch(
  () => props.fromList.map((item) => item.value),
  () => {
    setValue();
  },
  {
    immediate: true,
  }
);
watch(
  () => props.visible,
  () => {
    setValue();
  }
);
onMounted(() => {
  setValue();
});
</script>

<style lang="less" scoped>
.chengeStyle {
  :deep(.ant-form-item-label) {
    width: 100%;
    text-align: left;
  }
  :deep(.ant-form-item) {
    margin-bottom: 5px;
  }
}
.tips {
  :deep(.ant-form-item-required:after) {
    content: '（输入关键词按 “ENTER” 确认后继续输入，每个关键词在5字以内）';
    color: #6b778c;
    font-size: 12px;
  }
}

.tagBox {
  width: 100%;
  min-height: 35px;
  border: 1px solid #d9d9d9;
  padding: 5px 0;
  flex-wrap: wrap;
  .tag {
    background: #f2f2f2;
    padding: 2px 8px;
    margin-left: 5px;
    height: 25px;
    margin-bottom: 5px;
  }
  :deep(.ant-input) {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>
