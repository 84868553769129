<template>
  <a-modal v-model:visible="modalShow" width="80%" title="快速查车" footer="" @cancel="cancel">
    <a-form ref="formQueryRef" layout="inline" :model="searchForm">
      <a-form-item label="" name="driver">
        <a-cascader :options="$store.state.app.cityDict" allowClear v-model:value="searchForm.start" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地" />
      </a-form-item>
      <a-form-item label="" name="carNo">
        <a-cascader :options="$store.state.app.cityDict" allowClear v-model:value="searchForm.end" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" style="height: 38px;" placeholder="目的地" />
      </a-form-item>
      <a-form-item label="" name="orderId">
        <a-input v-model:value="searchForm.orderId" allowClear placeholder="订单号" />
      </a-form-item>
      <a-form-item label="" name="vinNo">
        <a-input v-model:value="searchForm.vinNo" allowClear placeholder="车架号" />
      </a-form-item>
      <a-form-item label="" name="salesman">
        <a-input v-model:value="searchForm.salesman" allowClear placeholder="业务员" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="loading" @click="onSearch">
          <template #icon>
            <SearchOutlined />
          </template>
          查询
        </a-button>
        <a-button style="margin-left:10px" :loading="loading" @click="resetBtn">
          重置
        </a-button>
      </a-form-item>
    </a-form>
    <!--    <a-input v-model:value="orderId" placeholder="输入订单号" ></a-input>-->
    <!--    <a-input-search v-model:value="vinNo" placeholder="输入车牌号查询" enter-button @search="onSearch" />-->
    <a-table :columns="columns" :row-selection="rowSelect" :rowKey="(record, index) => { return index }" :data-source="dataScore" :pagination="false" :loading="loading" :style="{ overflow: 'auto' }" size="small" bordered>
      <template #brand="{ record }">
        <div>
          <span v-show="record.brand || record.model">{{ record.brand + ' ' + record.model }}</span>
          <span v-show="!record.brand & !record.model">暂无车型数据</span>
        </div>
      </template>
      <template #transportType="{ record }">
        {{ record.transportType?.label }} - {{ record.transStatus?.label }}
      </template>
      <template #address="{ record }">
        <div>
          <div>起：{{ record.startAddress }}</div>
          <div>终：{{ record.endAddress }}</div>
        </div>
      </template>
      <template #detail="{ record }">
        <div>
          <a @click="lookDetail(record)">查看详情</a>
        </div>
      </template>
    </a-table>
    <!-- <a-list :loading="loading" size="small" bordered :data-source="dataScore">
      <template #renderItem="{ item }">
        <a-list-item>
          <div style="width:100%;display: flex;justify-content: space-between">
            <div style="flex:1;display: flex;">
              <div>{{ item.vinNo }}({{ item.brand + ' ' + item.model }})</div>
              <div>业务员({{ item.salesman }})</div>
              <div>({{ item.orderId }})</div>
              <div>({{ item.startAddress }})</div>
              <div>({{ item.endAddress }})</div>
              <div style="flex:1;display:flex;justify-content: space-between;margin:0 40px;">
                <div>{{ item.pushTime }}</div>
                <div v-show="item.currentShipper">调度方：{{ item.currentShipper }}</div>
              </div>
            </div>
            <div><a @click="lookDetail(item)">查看详情</a></div>
          </div>
        </a-list-item>
      </template>
    </a-list> -->
    <!-- 小车详情 -->
    <SmallVehDetails ref="smallVehDetailsRef"></SmallVehDetails>
  </a-modal>
  <a-modal v-model:visible="carDetailShow" width="80%" :destroyOnClose="true">
    <template #title>
      <div style="display:flex;justify-content: space-between">
        <span>车辆详情</span>
        <span style="margin-right:50px">
          <span style="margin-right:10px">{{ detailData.salesman }}</span>
          <span>{{ detailData.createTime }}</span>
        </span>
      </div>
    </template>
    <TotalDetail v-if="detailData" :element="{}" :orderId="detailData.orderId" :orderVehicleId="detailData.orderVehicleId" :index="0" />
  </a-modal>
  <a-modal v-model:visible="detailShow" footer="" width="65%" :bodyStyle="{ padding: '15px', paddingTop: 0 }">
    <template #title>
      <WindowDetail v-if="detailData.orderVehicleId" :detailData="detailData" />
    </template>
    <div class="left-content" style="width:auto">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true" @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { smallVehcileAll, vehicleAll } from '@/api/transport/truck'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'
import TotalDetail from '@/views/transportCapacity/transportUse/detail/totalDetail.vue'
import WindowDetail from './windowDetail'
import SmallVehDetails from '@/components/smallVehDetails'
import { message } from 'ant-design-vue'

export default {
  components: {
    TotalDetail,
    TransportModel,
    WindowDetail,
    SmallVehDetails
  },
  props: {
    showDOM: Boolean,
    vinNoIndex: Number,
    type: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const transportModelRef = ref(null)
    const smallVehDetailsRef = ref(null)
    const state = reactive({
      loading: false,
      modalShow: false,
      detailShow: false,
      deliveryLoading: false,
      carDetailShow: false,
      abnormalTask: '',
      vinNoIndex: props.vinNoIndex,
      dataScore: [],
      detailData: {},
      mirrorSearchForm: {},
      searchForm: {
        vinNo: '',
        orderId: '',
        salesman: '',
        startAddress: {
          province: null
        },
        endAddress: {
          province: null
        },
        start:null,
        end:null,
        current: 1
      },
      columns: [
        {
          title: '车牌/车架',
          dataIndex: 'vinNo'
        },
        {
          title: '车型',
          dataIndex: 'brand',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId'
        },
        {
          title: '运输线路',
          dataIndex: 'address',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '业务员',
          dataIndex: 'salesman'
        },
        {
          title: '下单时间',
          dataIndex: 'createTime'
        },
        {
          title: '车辆状态',
          dataIndex: 'transportType',
          slots: {
            customRender: 'transportType'
          }
        },
        {
          title: '调度备注',
          dataIndex: 'dispatchRemark'
        },
        {
          title: '调度方',
          dataIndex: 'currentShipper'
        },
        {
          title: '详情',
          dataIndex: 'detail',
          slots: {
            customRender: 'detail'
          }
        }
      ]
    })
    onMounted(() => {
      // if (props.show) state.showDOM = props.showDOM
      state.mirrorFrom = JSON.stringify(state.deliveryData)
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    watch(() => props.showDOM, (newVal, oldVal) => {
      state.modalShow = newVal
    })
    const cancel = () => {
      state.modalShow = false
      context.emit('statusShow', state.modalShow)
      setTimeout(() => {
        state.searchForm = {
          vinNo: '',
          orderId: '',
          salesman: '',
          startAddress: {
            province: null
          },
          endAddress: {
            province: null
          },
          current: 1
        }
        state.dataScore = []
      }, 300)
    }
    const onSearch = () => {
      if (state.searchForm.vinNo.length >= 5) {
        onVehicleAll()
        // state.carDetailShow = true
      } else if (state.searchForm.orderId.length >= 10) {
        onVehicleAll()
        // state.carDetailShow = true
      } else if (state.searchForm.salesman.length >= 2) {
        onVehicleAll()
        // state.carDetailShow = true
      } else if (state.searchForm.startAddress.province != null) {
        onVehicleAll()
        // state.carDetailShow = true
      } else if (state.searchForm.endAddress.province != null) {
        onVehicleAll()
        // state.carDetailShow = true
      } else {
        state.dataScore = [];
        message.error('请输入车牌号(至少五位数)或订单号(至少十位数) .起始地.目的地.业务员(至少二位数)')
      }
    }
    const resetBtn = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.dataScore = []
    }
    const onVehicleAll = () => {
      state.loading = true
      smallVehcileAll(state.searchForm).then(res => {
        if (res.code === 10000) {
          state.dataScore = res.data.records
        }
      }).finally(() => { state.loading = false })
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }
    const transportModelShowStatus = e => {
      if (e.orderId) state.detailData = e
    }
    const lookDetail = record => {
      if (props.type) {
        smallVehDetailsRef.value.detail = record
        smallVehDetailsRef.value.windowShow = true
        setTimeout(() => {
          smallVehDetailsRef.value.onOk()
        }, 100)
        return
      }
      state.detailData = record
      state.detailShow = true
      // state.carDetailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    return {
      ...toRefs(state),
      transportModelRef,
      onSearch,
      lookDetail,
      transportModelShowStatus,
      setStartAddress,
      setEndAddress,
      onVehicleAll,
      resetBtn,
      cancel,
      smallVehDetailsRef
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
.delivery-content {
  position: reactive;
}
</style>
