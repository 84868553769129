<template>
  <div ref="modal" class="classmodal">
    <a-modal :getContainer="()=>$refs.modal" :maskClosable="false" centered :visible="visible" footer="" @cancel="cancel" width="85%" :bodyStyle="{ padding:'10px',background:'#EDF0F9' }">
      <template #title>
        <div class="titleBox flex al-center">
          <!-- <a-dropdown v-model:visible="dropdownVisible">
            <a class="ant-dropdown-link" @click="dropdownVisible = true">
              筛选
            </a>
            <template #overlay>
              <div class="dropdownBox">
                <div class=" flex wrap  al-center">
                  <div v-for="item in  screen" class="m-r1" :key="item.id">
                    <a-checkbox v-model:checked="item.checked">{{item.label}}</a-checkbox>
                  </div>
                </div>
                <div class="flex ju-end m-t1">
                  <a-button size="small" :loading="btnLoading" @click="searchAdd" type="primary">确认</a-button>
                </div>
              </div>
            </template>
          </a-dropdown> -->
          <div @click="openConfig" class="m-l2 cur-p" style="color:#6b778c">配置</div>
          <img @click="openColor" class="m-l2 cur-p" style="width:18px;height:18px" src="@/assets/images/newTransport/colorChange.png" alt="">

          <div class="taskInfo flex al-center ju-end">
            <div style="margin-right: 80px;">
              <a-tooltip>
                <template #title>
                  <div style="min-width: 130px">
                    <div v-for="item in todayDoneCountList" :key="item.id" class="flex ju-between">
                      {{item.title}} <span>{{ item.value }}</span>
                    </div>
                  </div>
                </template>
                <div class="m-r1 flex al-center cur-p">
                  今日已操作总数：
                  <CountTo :endVal="todayDoneCount" color="#0066CC" :loadingNum="loadingNum" fontSize="18px" />
                </div>
              </a-tooltip>
            </div>
            <div>
              <span>本月准时率：</span>
              <span class="m-r1">委托 {{timeRate.commission}}%</span>
              <span class="m-r1">验车 {{timeRate.inspection}}%</span>
              <span class="m-r1">提车 {{timeRate.pick}}%</span>
              <span class="m-r1">干线 {{timeRate.trans}}%</span>
              <span class="m-r1">送车 {{timeRate.delivery}}%</span>
            </div>
          </div>
        </div>
      </template>
      <div v-if="loading" class="flex al-center ju-center" style="height: 300px">
        <a-spin />
      </div>
      <div v-if="data.length>0" class="contenBox">
        <div v-for="(item,index) in data" :key="item.id" class="conten" :class="(index+1)%5 === 0 ? 'changeMar':''" :style="{top:item.top+'px'}" style="max-height: 550px;overflow: auto;">
          <div class="topBox" :style="{background:item.back}">
            <div class="titleText flex ju-between">
              <span class="flex al-center">
                <img style="width: 18px;height:18px" :src="require(`@/assets/images/newTransport/icon${item.styleType}.png`)" alt="">
                <span class="m-l1">{{ item.title }}</span>
              </span>
              <a-tooltip>
                <template #title>
                  <div v-html="item.define"></div>
                </template>
                <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
              </a-tooltip>
            </div>
            <div v-if="item.subItemList" :class="{'custom':typeList.includes(item.styleType)}" >
              <div v-for=" opt in item.subItemList" :key="opt.id" :style="{width:item.customWid}" >
                <Meun :msg="opt" :vehicleNumType="vehicleNumType" :subItemList="item.subItemList" :styleType="item.styleType"></Meun>
              </div>
            </div>
            <div v-else>
              <Meun :msg="item" :vehicleNumType="vehicleNumType" :styleType="item.styleType"></Meun>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <ConfigMoadl @success="success" v-if="configVisible" :selProvince="dispatchProvinceList" :vehicleNumType="vehicleNumType" :selEmployee="reliefList" v-model:visible="configVisible"> </ConfigMoadl>
      <ColorMoadl v-model:visible="colorVisible" :data="data"></ColorMoadl>
    </a-modal>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, onBeforeUnmount } from 'vue'
import CountTo from '@/components/countTo'
import Meun from './meun'
import { pageAll } from '@/api/newTransport/newTransport'
import ConfigMoadl from './components/configAdd/configMoadl'
import ColorMoadl from './components/colorMoadl'
import { message } from 'ant-design-vue'


const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const  timer = ref(null)
const btnLoading = ref(false)
const todayDoneCount = ref(0)
const settTiming = ref(10)
const colorData = ref([])
const colorVisible = ref(false)
const loadingNum = ref(0)
const loading = ref(false)
const data = ref([])
const timeRate = ref({})
const dispatchProvinceList = ref([])
const reliefList = ref([])
const configVisible = ref(false)
const screenList = ref([])
const screen = ref([])
const typeList = ref([2, 7])
const vehicleNumType = ref(0)

const openColor = () => {
  colorVisible.value = true
}
const openConfig = () => {
  configVisible.value = true
}

const cancel = () => {
  clearInterval(timer.value)
  emit('update:visible', false)
}
const success = () => {
  if (!timer.value) {
    settTiming.value = 10
    openTime()
  }
}
const searchAdd = () => {
  let arr = screen.value.filter(item => item.checked)
  screenList.value = arr.map(item => item.value)
  reqPageAll()
}
const reqPageAll = (type) => {
  clearInterval(timer.value)
  timer.value = null
  if (!loadingNum.value) {
    loading.value = true
  }
  btnLoading.value = true
  pageAll({
    dispatchProvinceList: screenList.value
  }).then(res => {
    if (res.code === 10000) {
      timeRate.value = res.data.timeRate
      todayDoneCount.value = res.data.todayDoneCount.total
      dispatchProvinceList.value = res.data.dispatchProvinceList
      reliefList.value = res.data.reliefList
      vehicleNumType.value = res.data.vehicleNumType
      data.value = res.data.consoleList
      todayDoneCountList.value.forEach(item => {
        item.value = res.data.todayDoneCount[item.key]
      })
      if (!loadingNum.value || type === 'change') {
        screen.value = res.data.dispatchProvinceList
        screen.value.forEach(item => {
          item.checked = true
        })
      }
      if (localStorage.getItem('colorData')) {
        colorData.value = JSON.parse(localStorage.getItem('colorData'))
      }
      data.value.forEach(item => {
        item.back = '#fff'
        if (item.styleType === 2) {
          item.customWid = '49%'
        }
        if (item.styleType === 7) {
          item.customWid = '33%'
        }
        colorData.value.forEach(opt => {
          if (opt.title === item.title) {
            item.back = opt.back
          }
        })
      })
      settTiming.value = 10
      openTime()
      nextTick(() => {
        let list = document.querySelectorAll('.topBox')
        for (let i = 0; i < list.length; i++) {
          data.value[i].clientHeight = list[i].clientHeight
        }
        let maxList2 = data.value.slice(0, 5).map(item => item.clientHeight)
        let max2 = Math.max.apply(null, maxList2)
        let maxList3 = data.value.slice(5, 5 * 2).map(item => item.clientHeight)
        let max3 = Math.max.apply(null, maxList3)
        data.value.forEach((item, index) => {
          if ((index + 1) / 5 > 1 && (index + 1) / 5 <= 2) {
            item.top = data.value[index - 5].clientHeight - max2
          }
          if ((index + 1) / 5 > 2 && (index + 1) / 5 <= 3) {
            item.top = (data.value[index - 5].clientHeight + data.value[index - 5].top) - max3
          }
        })
      })
    } else {
      if (res.code === 10101) {
        clearInterval(timer.value)
        return
      }
      if(!timer.value){
        settTiming.value = 30
        openTime()
      }
    }
  }).finally(() => {
    btnLoading.value = false
    loading.value = false
    loadingNum.value = 1
  })
}
const openTime = () => {
  timer.value = setInterval(() => {
    reqPageAll()
  }, settTiming.value * 1000)
}
onBeforeUnmount(() => {
  clearInterval(timer.value)
  timer.value = null
});
onMounted(() => {
  reqPageAll()

})
const todayDoneCountList = ref([
  { title: '在途跟踪次数', value: 0, key: 'onWayTrack' },
  { title: '验车照检查', value: 0, key: 'vehicleInspectionPic' },
  { title: '发车计划', value: 0, key: 'waitPlan' },
  { title: '交车条检查', value: 0, key: 'vehicleDelivery' },
  { title: '验车任务', value: 0, key: 'inspection' },
  { title: '提任务', value: 0, key: 'pick' },
  { title: '干任务', value: 0, key: 'transport' },
  { title: '送任务', value: 0, key: 'takeout' },
  { title: '外派任务', value: 0, key: 'task' },
])
</script>

<style lang="less" scoped>
.waterfall {
  position: relative;
}
.waterfall-item {
  position: absolute;
  padding: 10px;
  background: red;
}
.classmodal {
  :deep(.ant-modal-header) {
    padding: 10px !important;
  }
}

.titleBox {
  font-weight: normal;
  font-size: 13px;
}

.taskInfo {
  width: 84%;
  color: #344563;
}

.contenBox {
  display: flex;
  flex-wrap: wrap;
}

.conten {
  width: 19%;
  position: relative;
  margin-right: 15px;
  .topBox {
    width: 100%;
    min-height: 100px;
    background: #fff;
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 2px;
  }
}

.titleText {
  color: #333;
  font-size: 15px;
  font-weight: bold;
}
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.itemTitle {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
}

.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-bottom: 10px;
  border-left: 5px solid transparent;
}

.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}

.customTags {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
  width: 49%;
}

.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.outerBox {
  padding: 25px 10px;
  background: #f7f8fc;
  border-radius: 4px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}

.tipsIcon {
  width: 16px;
  height: 16px;
}

.custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.changeMar {
  margin-right: 0;
}

.dropdownBox {
  padding: 10px;
  width: 280px;
  box-shadow: 0 2px 5px #333;
  background: #fff;
}
</style>