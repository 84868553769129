<template>
  <div>
    <div ref="addCloudModal">
      <a-modal width="50%" :centered="true" :visible="visible" title="调度日志" :footer="null" @cancel="cancel" :getContainer="() => $refs.addCloudModal">
        <div class="flex ju-between">
          <div class="logsList m-l1">
            <div class="overBox">
              <div v-if="loading" class="m-t4 flex al-center ju-center">
                <a-spin />
              </div>
              <div v-else>
                <div v-if="dataList.length == 0" class="m-t4">
                  <a-empty />
                </div>
                <div v-else>
                  <div v-for="item in dataList" :key="item.id" class="m-t2 itemBox pos-rel">
                    <div class="pos-abs" style="right:0"><a @click="copyFun(item)">复制</a></div>
                   <div style="width: 90%;">
                    <div v-if="item.carrierName">承运商：<span>{{ item.carrierName }}</span></div>
                    <div v-if="item.cost">本段价格：<span>{{ item.cost }}元</span></div>
                    <div v-if="item.lineName">本段线路：<span>{{ item.lineName }}</span></div>
                    <div v-if="item.arrangeRemark">工作交接留言：<span>{{ item.arrangeRemark }}</span></div>
                    <div v-if="item.labelName">自定义标签：<span>{{ item.labelName }}</span></div>
                    <div v-if="item.arrangeLoadTime">计划装车时间：<span>{{ item.arrangeLoadTime }}</span></div>
                    <div v-if="item.exceptionType?.label">异常类型：<span>{{ item.exceptionType?.label }}</span></div>
                    <div v-if="item.exceptionSubType?.label">异常原因：<span>{{ item.exceptionSubType?.label }}</span></div>
                    <div v-if="item.exceptionExplain">异常情况说明：<span>{{ item.exceptionExplain }}</span></div>
                    <div style="margin:15px 0;color:#B5BBC5;width:100%" class="flex ju-between">
                      <div> {{ item.arranger }} </div>{{ item.createTime }}
                    </div>
                   </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="m-t2 m-b1 flex ju-center">
              <a-pagination v-model:current="pagination.current" @change="changePage" size="small" :page-size="pagination.size" :total="pagination.total" :show-total="pagination.showTotal" />
            </div>
          </div>
          <div class="formBox">
            <div class="title m-t2 m-b2">
              部门交接内容
            </div>
            <div class="flex ju-between">
              <div style="width:32%">
                <span>承运商</span>
                <a-input v-model:value="fromOptions.carrierName" placeholder="请输入" />
              </div>
              <div style="width:32%">
                <span>本段路线</span>
                <a-input v-model:value="fromOptions.lineName" placeholder="请输入" />
              </div>
              <div style="width:32%">
                <span>本段价格</span>
                <a-input v-model:value="fromOptions.cost" placeholder="请输入" />
              </div>
            </div>
            <div class="m-t1">
              <span>工作交接留言</span>
              <div>
                <a-textarea :rows="3" v-model:value="fromOptions.arrangeRemark" />
              </div>
            </div>
            <div>
             <div class="m-t1" style="margin-bottom:5px">自定义标签</div>
              <a-select  style="width:33%" show-search  @change="celLabel"  :filterOption="filterOption" v-model:value="fromOptions.labelId" placeholder="选择标签">
                  <a-select-option  v-for="opt in $store.state.app.labelList" :lable="opt.name" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
                </a-select>
            </div>
            <div class="flex al-center m-t2 m-b2">
              <div class="title">
                同步内容
              </div>
              <span>（状态为必填时会同步到业务员和日志）</span>
            </div>
            <div>
              <div><span v-if="isOverTime.isOverTime == 1&&isOverTime.transportType==2" class="reqText">*</span>预计发车时间</div>
              <a-date-picker :disabled-date="disabledDate" style="width:33%" v-model:value="fromOptions.arrangeLoadTimeStr" />
            </div>
            <div>
              <div class="m-t1"><span v-if="isOverTime.isOverTime == 1" class="reqText">*</span>异常类型<span style="font-size:12px">(异常上报)</span></div>
              <a-select style="width: 33%" allowClear v-model:value="fromOptions.exceptionType" placeholder="请选择" :not-found-content="null">
                <a-select-option v-for="(type,index) in exceptionTypes" :key="type[1]">{{ type[0] }}</a-select-option>
              </a-select>
            </div>
            <div>
              <div class="m-t1"><span v-if="isOverTime.isOverTime == 1" class="reqText">*</span>异常原因</div>
              <a-select style="width: 33%" allowClear v-model:value="fromOptions.exceptionSubType" placeholder="请选择" :not-found-content="null">
                <a-select-option v-for="(type,index) in exceptionExplains" :key="type[1]">{{ type[0] }}</a-select-option>
              </a-select>
            </div>
            <div class="m-t1">
              <span v-if="isOverTime.isOverTime == 1" class="reqText">*</span> 异常情况说明
              <div>
                <a-textarea :rows="3" v-model:value="fromOptions.exceptionExplain" />
              </div>
            </div>
            <div class="m-t4 m-b1 flex ju-end">
              <a-button type="primary" :loading="comLoading" @click="submit">确定</a-button>
            </div>

          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment'
import { onMounted, reactive, ref } from 'vue'
import { arrangePage, setRemark } from '@/api/transport/transport'
import transportType from '@/config/TransportType.json'
import { transitionTimeYMD,compareTimeAad } from '@/utils/util.js'
import { message, Modal } from 'ant-design-vue'
const props = defineProps({
  orderVehicleId: {
    type: Number,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  remarkId: {
    type: String,
    default: ''
  },
  isOverTime: {
    type: Object,
    default: {}
  }
})
 // 模糊搜索
 const filterOption = (input, option) => {
  return option.lable.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
 // 禁用时间
 const disabledDate = current => {
  return current && current < moment().subtract(1,"days");
    }
const pagination = ref({
  total: '',
  showTotal: (total) => `共${total}条`,
  current: 1,
  size: 4
})
const fromOptions = reactive({
  carrierName: '',
  lineName: '',
  cost: '',
  arrangeRemark: '',
  arrangeLoadTimeStr: '',
  exceptionType: null,
  exceptionSubType: null,
  exceptionExplain: '',
  labelId:null
})
const labelName = ref('')
const comLoading = ref(false)
const loading = ref(false)
const dataList = ref([])
const exceptionExplains = ref(transportType.exceptionExplain)
const exceptionTypes = ref(transportType.exceptionType)
const emit = defineEmits(['update:visible','setRemaekOk'])

const celLabel = (e,v) => {
   labelName.value = v.lable
}
const copyFun = (item) => {
   for(let key in fromOptions){
      fromOptions[key] = item[key]
   }
    fromOptions.exceptionSubType = fromOptions.exceptionSubType?.value
    fromOptions.exceptionType = fromOptions.exceptionType?.value
    fromOptions.arrangeLoadTimeStr = null
}
const submit = () => {
  let options = JSON.parse(JSON.stringify(fromOptions))
  options.arrangeLoadTimeStr = transitionTimeYMD(options.arrangeLoadTimeStr)
  let isOverTime = props.isOverTime
  if (isOverTime.isOverTime == 1 && isOverTime.transportType == 2 ) {
    if (!options.arrangeLoadTimeStr) {
      message.error('请选择计划装车时间')
      return
    }
  }
  if (isOverTime.isOverTime == 1) {
    if (!fromOptions.exceptionType && fromOptions.exceptionType !== 0) {
      message.error('请选择异常类型')
      return
    }

    if (!fromOptions.exceptionSubType) {
      message.error('请选择异常原因')
      return
    }
    if (!fromOptions.exceptionExplain) {
      message.error('请填写异常情况说明')
      return
    }
  }
  const allPropertiesAreEmpty = Object.keys(options).every(key => options[key] == null || options[key] === '');
  if(allPropertiesAreEmpty){
    message.error('请填写工作交接留言')
    return
  }
  let obj = {
    dispatchId: props.remarkId,
    orderVehicleId: props.orderVehicleId
  }
  Object.assign(obj, options)
  comLoading.value = true
  setRemark(obj).then(res => {
    if (res.code === 10000) {
      message.success(res.msg)
      pagination.value.current = 1
      reqArrangePage()
      dispatchData(options.dispatchId, options.orderVehicleId)
      let str = ''
      if(obj.carrierName){
        str += obj.carrierName + ' '
      }
      if(obj.lineName){
        str += obj.lineName + ' '
      }
      if(obj.cost){ 
        str += obj.cost + ' '
      }
      if(obj.arrangeRemark){
        str += obj.arrangeRemark
      }
       emit('setRemaekOk', str,labelName.value,options.arrangeLoadTimeStr)
    }
  }).finally(() => { comLoading.value = false })
}
const dispatchData = (remarkId, orderVehicleId) => {
  for (let i in fromOptions) {
    fromOptions[i] = null
  }
}
const changePage = (e) => {
  pagination.value.current = e
  reqArrangePage()
}
const reqArrangePage = () => {
  loading.value = true
  arrangePage({
    orderVehicleId: props.orderVehicleId,
    current: pagination.value.current,
    size: pagination.value.size
  }).then((res) => {
    if (res.code !== 10000) return
    dataList.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const cancel = () => {
  emit('update:visible', false)
}
onMounted(() => {
  reqArrangePage()
})

</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  padding: 0 !important;
  background: #f4f5f7 !important;
}
:deep(.ant-btn-primary) {
  border-color: #1890ff !important;
  background-color: #1890ff !important;
}
.logsList {
  width: 49%;
  padding: 10px 0 10px 25px;
  background: #fff;
  .overBox {
    padding-right: 20px;
    height: 660px;
    overflow: auto;
  }
  .itemBox {
    border-bottom: 1px solid #eeeeee;
    color: #6b778c;
    span {
      color: #344563;
    }
  }
}
.overBox::-webkit-scrollbar {
  width: 5px;
}
/* 滚动条的轨道 */
.overBox::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #fff;
  cursor: pointer;
}
/* 滚动条的滑块 */
.overBox::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: #daebfa;
  width: 5px;
  border-radius: 8px;
}
.formBox {
  width: 49%;
  padding: 10px 20px;
  background: #fff;
  .title {
    color: #344563;
    font-size: 16px;
    font-weight: 600;
  }
  .reqText {
    font-family: SimSun, sans-serif;
    color: red;
  }
}
</style>