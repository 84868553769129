<template>
  <div>
    <div class="flex-d ju-center itemBox al-center m-t1 m-b1 cur-p" @click="openModal(item)">
      <img :src="url" alt="">
      <div class="m-t1"> {{ item.title }}</div>
    </div>
    <BatchCheckCar ref="BatchCheckCarRef" />
    <BatchAccomplish v-model:batchAccomplishShow="batchAccomplishShow" />
    <BatchEmpty v-model:emptyShow="emptyShow" v-if="emptyShow" />
    <BatchReport v-model:batchReportShow="batchReportShow" />
    <QuotationInquiry v-if="quotationShow" v-model:visible="quotationShow" />
    <BigAddPlan v-model:addPlanShow="addPlanShow" />
    <SendOrder ref="SendOrderRef"></SendOrder>
    <!-- <SmallVehicleInquiry v-if="SmallVehVisible" v-model:visible="SmallVehVisible"></SmallVehicleInquiry> -->
    <VinNo :vinNoIndex="vinNoIndex" :showDOM="SmallVehVisible" :type="1"  @statusShow="vinnoStatusChange" />
    <BigVehicleIquiry v-if="BigVehVisible" v-model:visible="BigVehVisible" />
    <OuterOps v-if="opsVehVisible" v-model:visible="opsVehVisible" />
    <BatchDelivery ref="BatchDeliveryRef"></BatchDelivery>
  </div>
</template>

<script setup> 
import { ref } from 'vue'
import BigVehicleIquiry from './components/bigVehicleInquiry'
import BatchCheckCar from './components/batchCheckCar'
import BatchAccomplish from './components/batchAccomplish'
import BatchEmpty from './components/batchEmpty'
import BatchReport from './components/batchReport'
import BigAddPlan from './components/bigAddPlan'
import SendOrder from './components/sendOrder'
import QuotationInquiry from './components/quotationInquiry'
import VinNo from '@/views/components/vinNo'
import OuterOps from './components/outerOps'
import BatchDelivery from './components/batchDelivery'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const url = require(`@/assets/images/newTransport/fun${props.item.pageType}.png`)
const BigVehVisible = ref(false)
const BatchCheckCarRef = ref(null)
const batchAccomplishShow = ref(false)
const emptyShow = ref(false)
const batchReportShow = ref(false)
const addPlanShow = ref(false)
const SendOrderRef = ref(null)
const quotationShow = ref(false)
const SmallVehVisible = ref(false)
const opsVehVisible = ref(false)
const BatchDeliveryRef = ref(null)

const openModal = (item) => {
  switch (item.pageType) {
    case 71:
      BatchCheckCarRef.value.batchOperateShow = true
      break;
    case 72:
      batchAccomplishShow.value = true
      break;
    case 73:
      emptyShow.value = true
      break;
    case 74:
      batchReportShow.value = true
      break;
    case 75:
      quotationShow.value = true
      break;
    case 76:
      addPlanShow.value = true
      break;
    case 77:
      SendOrderRef.value.sendShow = true
      break;
    case 78:
      SmallVehVisible.value = true
      break;
    case 79:
      BigVehVisible.value = true
      break;
    case 710:
      opsVehVisible.value = true
      break;
      case 711:
      BatchDeliveryRef.value.batchGiveShow = true
      BatchDeliveryRef.value.reqData()
      break;
  }
}
const vinnoStatusChange = () => {
  SmallVehVisible.value = false
}
</script>

<style lang="less" scoped>
.itemBox {
  font-size: 14px;
  color: #332;
  img {
    width: 50px;
    height: 45px;
  }
}
</style>